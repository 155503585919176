@import "@angular/material/prebuilt-themes/indigo-pink.css";
//@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/list/main.css';

.fc-event{
  min-height: 20px !important;
}

.mat-sidenav-container, .mat-sidenav-content, .mat-tab-body-content {
  transform: none !important;
}


html,
body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  /*  -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;*/
    height: 100vh;
    width: 100vw;
    border-collapse: collapse;

}

.avatarFace{
  border-radius: 50%; width: 120px; height: 120px; margin: 20px; background-size: cover; background-position: center center;
  position: relative;
  cursor: pointer;
}




.avatarName{
  z-index: 100;
  color: white;
  opacity : 0;
  font-size: 10px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform : translate(-50%, -50%);
}

.avatar2{
  background-position:center center;
  /* background-image: url('https://bmyscouter.co/db/mobile/images/user5751ec3e9a4feab575962e78e006250d/avatar1540311334.jpg');*/
   background-size: cover;
   border-radius: 50%;
   width: 80px;
   height: 80px;
}


.centred{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}

.event-page{
    overflow: auto;
    position: relative;

  }

  .button5{
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 10px;
    border-radius: 5px;
    width: 80%;
    padding: 10px;
    background-color: #FF5C3B;
    color: white;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align:center;
    cursor:pointer;
  }
  
  .event-card{
    position: relative;
    background-size: cover;
    background-position: center center;
    border: 1px solid white;
   /* margin: 10px;*/
    height: 210px;
    width: 120px;
    float: left;
    border-radius: 1px;

    cursor: pointer;
  }

  .agency-card{
    position: relative;
    border: 1px solid black;
    margin: 10px;
    height: 110px;
    width:110px;
    float: left;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
  }



  .reveal{
    transition-duration: 0.12s;
      opacity:1 !important;
  }
  
  .modelInfos{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: #212121;
  /*  border-radius: 50%;*/
    opacity:0;
    transition-duration: 0.5s;
    z-index:100;
  }

  .agencyInfos{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #00dbbf;
    width: 100%;
    height: 100%;
    opacity:0;
    z-index:100;
    transition-duration: 0.5s;
    border-radius: 50%;
  }

  .agencyInfos2{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #00dbbf;
    width: 100%;
    height: 100%;
    opacity:0;
    z-index:100;
    transition-duration: 0.5s;
    border-radius: 50%;
    transition-duration: 0.12s;
    opacity:0.9 !important;
  }


  .name{
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
    color:white;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    white-space: nowrap;
  }

  .registration_type{
    position:absolute;top:5px;right:5px;height:25px;
  }

  .check_model{
    position:absolute;top:5px;left:5px;
  }

  .avatar{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    width:50px;
    border-radius:50%;
  }

  .actions{
    background-color: #fff;
    margin: 5px 0;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12);
    cursor: pointer;
    position: relative;
    -webkit-transition: background-color 0.3s linear;
    -ms-transition: background-color 0.3s linear;
    transition: background-color 0.3s linear;
}

.actions:hover{
    color: #262626;
    background-color: #DFEBFA;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%; 
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.bTnStandard{
  text-align:center; 
  width: 30%;
  max-width: 90px;
  padding:10px;
  right: 10%;
  letter-spacing:1px;
  background-color: black;
  color: white;
  border-radius:50px;
  border-radius: 5px;
  cursor: pointer;
}

.roboto{
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.nopadding-box {
  mat-dialog-container {
      padding: 20px;
  }
}

.agency-card:hover{
  -webkit-box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.75);
}


::ng-deep.mat-tab-label, ::ng-deep.mat-tab-label-active{
  min-width: 0!important;
  padding: 5px!important;
  margin-right: 10px!important;
  margin-left: 10px!important;
 }


 .paid{
  background-color : rgba(84, 229, 13, 0.6);
}

.retard{
  background-color: rgba(255, 0, 0, 0.5);
}

.verylate{
  background-color: rgba(255, 0, 0, 0.9);
}

  
.agendaNotif{
  border: 1px solid black;
  opacity: 1;
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  right: 35px;
  height: 85%;
  width: 400px;
  z-index: 2500;
  background-color: white;

}

.removeOnMobile{
  display: block;
}

.grid-stats-ctn{
  background-color: #d5d5d5;
  padding: 5px;
  margin: 5px 10px;
}

.grid-stats-ctn-title{
  text-transform: uppercase;
  font-weight: 700;
  font-size: 22px;
}

.grid-stats-flex-row{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.grid-stats-flex-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grid-box{
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: white;
  flex: 1;
  flex-grow: 1;
  flex-basis: 0;
}

.grid-box-value{
  font-size: 32px;
  font-weight: 700;
}

.grid-box-label{
  text-transform: uppercase;
  font-size: 22px;
  text-align: center;
}

.grid-box-ctn{
  margin: 5px;
  display: flex;
  flex-direction: column;
 
  flex: 1;
  flex-grow: 1;
  flex-basis: 0;
}

.triangleVertPositif{
  margin-top: 5px;
  width: 0;
height: 0;
border-style: solid;
border-width: 0 15px 20px 15px;
border-color: transparent transparent #54e50d transparent;
}

.triangleRougeNegatif{
  margin-top: 5px;
  width: 0;
height: 0;
border-style: solid;
border-width: 20px 15px 0 15px;
border-color: red transparent transparent transparent;
}

.grid-box-figure{
  font-weight: 700;
  font-size: 22px;
}

.percentFlex{
  display: grid;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.marginTop20OnLargeDesktop{
  margin-top: 20px;
}


.mobileMenuOpen{
  width: 250px !important;
  transition: all 0.3s ease-in-out;    
}


.bTnPannelForMobile{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Bigagency-card{
  border: 1px solid black;
  border-radius: 50%;
  max-width: 220px;
  max-height: 220px;
  width : calc(calc(100vw - 80px) / 2);
  height : calc(calc(100vw - 80px) / 2);
  cursor: pointer;
 
}

.Bigagency-card:hover{
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}



@media only screen and (max-width: 650px) {

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

  .fc-center {
    margin-top: 10px
 }

 .fc-right {
     margin-top: 10px
  }


 .fc-toolbar.fc-header-toolbar {
     flex-direction: column;
 }

  .paddingTopOnMobile{
    padding-top: 40px !important;
}

.BtnLongOnMobile{
  width: 100%;
}


  .modalCloseBtn{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(0,0,0,0.75);
    position: fixed;
    top: 10px;
    right: 20px;
    cursor: pointer;
    z-index: 100;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  }

  .marginTop20OnLargeDesktop{
    margin-top: 0;
  }

  .removeOnMobile{
    display: none !important;
  }



  .grid-stats-flex-row{
    display: flex;
    flex-direction: column;
  }

  .event-card{

    margin: 0;
    height: 210px;
    width: 25%;
    border-radius: 1px;

    cursor: pointer;
  }

  /*
  #cdk-overlay-0{
    max-width: 100%;
    min-width: 100%;
    overflow: scroll;
    min-height: 100%;
  }

  #cdk-overlay-1{
    max-width: 100%;
    min-width: 100%;
  }
*/
  .nopadding-box {
    mat-dialog-container {
      max-width: 100%;
      min-width: 100%;
      overflow: scroll;
      min-height: 100vh;
      padding-top: 0px;
      padding-right : 0px;
      padding-left: 0px;
      margin : 0px;
    }
  }

  .padding-top{
    mat-dialog-container{
      padding-top: 40px !important
    }
  }


  .nopadding-box{
    max-width: 100vw !important;
    min-width: 100vw !important;
    min-height: 100vh;
    overflow-y: scroll;
  }
  

  .avatarFace{
    width : calc(calc(100vw - 80px) / 4);
    height : calc(calc(100vw - 80px) / 4);

    margin: 10px; 
    position: relative;
    cursor: pointer;
  }

  .Bigagency-card{
    border: 1px solid black;
    border-radius: 50%;
    width : calc(calc(100vw - 80px) / 2);
    height : calc(calc(100vw - 80px) / 2);
  }
  

  .agency-card{

    width : calc(calc(100vw - 80px) / 4);
    height : calc(calc(100vw - 80px) / 4);

  }

  .bTnPannelForMobile{
    position: fixed;
    bottom: 3%;
    right: 5%;
    z-index: 100;
    display: flex;
    flex-direction: row;
  }

  .bTnPannelForMobile button{
    width: 65Px;
    height: 65Px;
    border-radius: 50%;
    color: white;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);


  }


  .bTnPannelForMobile button:nth-child(1){
    margin-right: 10px;
    background-color: #b26a6a;
  

  }
    .bTnPannelForMobile button:nth-child(2){
      
      background-color: #3cb371;
    }
  
  }

  

